@import '../../core/constants.scss';

.#{$root-element} {
  &--scaleOutAnimation {
    --scale-out-scale: 0.85;

    .#{$root-element} {
      &--exit {
        &.#{$root-element}--moveRight,
        &.#{$root-element}--moveLeft {
          animation: scaleOut var(--slider-transition-duration) both
            var(--transition-bezier);
        }
      }
    }
  }
}

@keyframes scaleOut {
  to {
    transform: scale(var(--scale-out-scale));
  }
}
